@use '@/styles/utils/mixins.scss' as *;

.productWrapper {
  display: flex;
  flex-direction: column;
  gap: 6.25rem;
  @include tab() {
    gap: 3.75rem;
  }
  .sectionHeadingContainer {
    display: flex;
    gap: 2.5rem;
    justify-content: space-between;
    @include tab() {
      flex-direction: column;
    }
  }
  .sectionDescription {
    max-width: 29rem;
  }
  .categoryLists {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    @include tab() {
      gap: 3.75rem;
    }
  }
}
